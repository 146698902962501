import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Button } from "../elements/button"
import ContactForm from "../components/contactForm"
import Accordion from "../components/accordion"
import { Link } from "react-router-dom";

const questionsWithMarkup = {
    "question4": <>Living wage benchmarks rely on international standards and local data costs to calculate the cost of basic but decent life. Research has shown that the key components of a credible living wage benchmark include the four following costs: <ul className="list-disc my-4 pl-8"><li>Food using international standards on decent food and local data on food costs</li><li>Housing using international standards on decent housing and local data on housing costs</li><li>Other essential needs such as transportation and healthcare</li><li>Small margin for unforeseen events such as illness or accidents</li></ul><a target="_blank" className="text-primary" href="https://www.youtube.com/watch?v=l5VW259OroQ" rel="noreferrer">https://www.youtube.com/watch?v=l5VW259OroQ</a></>,
    "question6": <>The vast majority of garment and textile workers around the world earn very low wages and many are trapped in in-work poverty. The minimum wage often does not allow workers to meet basic needs. Without a living wage workers may be:<ul className="list-disc my-4 pl-8"><li>forced to work excessive overtime hours or multiple jobs</li><li>forced to take their children out of school and put them into work</li><li>unable to afford food, health, housing and education</li><li>unable to withstand crises such as ill- health or other unexpected events</li><li>forced to borrow and take loans to purchase basic goods ending up in a cycle of debt</li></ul></>,
    "question9": <>Most brands do not own the factories where their clothes are made and outsource their manufacturing to suppliers to reduce costs. The vast majority of the people who make our clothes are directly employed and paid by suppliers; not brands.<br />If a brand says that they are paying fair wages, be skeptical. Brands pay prices for the products they buy from their supply chain partners. Suppliers and manufacturers pay workers wages/salaries. Find out more: <a target="_blank" className="text-primary" href="https://www.goodclothesfairpay.eu/faqs" rel="noreferrer">https://www.goodclothesfairpay.eu/faqs</a></>,
    "question10": <>Not only are we committed to good working conditions and fair payment for those who produce our T-shirt, we are also dedicated to taking the next step towards a sustainable and ecologically friendly.<br /><br />We do this through our Cradle to Cradle and certification, which means that our product is completely made with the environment in mind, no pesticides, renewable energy, and fully compostable materials. <br /><br />To find out more about the C2C®* certification, visit <a target="_blank" className="text-primary" href="https://c2ccertified.org" rel="noreferrer">https://c2ccertified.org</a> <br /><br />Additionally, our production partner Detto Fatto is certified by the Green Button, a government run certification label for sustainable textiles that systematically evaluates whether companies take responsibility for respecting human rights and environmental standards in their supply chains.To find out more about the Green Button, visit <a target="_blank" className="text-primary" href= "https://www.gruener-knopf.de/en/overview-criteria" rel = "noreferrer">https://www.gruener-knopf.de/en/overview-criteria</a></>,
    "question14": <>Check out our partners page <Link className="text-primary" to="/partners">here.</Link></>
}

export function Questions() {
    const { t, i18n } = useTranslation();

    return (
        <main className="partners">
            <section className="container mx-auto mb-16 sm:mb-16">
                <div className="header-img w-full">
                    <img src="/Subpages/faq_header.jpg" alt="" className="max-w-full w-full" />
                </div>
            </section>
            <section className="container mx-auto mb-16 sm:mb-32 sm:px-32 px-8">
                <div className="">
                    <h1 className="w-full text-center uppercase text-4xl sm:text-5xl mb-12 font-extrabold montserrat-black">{t("questions.title")}</h1>
                </div>
            </section>
            <section className="container mx-auto mb-16 sm:mb-32 sm:px-32 px-8">
                <h2 className="uppercase text-2xl mb-12 font-extrabold">{t("questions.section1.title")}</h2>
                <Accordion activeItem="question1">
                    <div data-id="question1">
                        <span className="">{t(`questions.section1.question1.title`)}</span>
                        <div className="">
                            <Trans i18nKey={`questions.section1.question1.text`}>
                                Only beautiful and sustainable is not enough for us. The textile production chains are long and in every single garment is the work of many people and a lot of time. Our production partner DETTO FATTO is committed to decent working conditions and adequate payment for the people who make the T-Shirt.<br/><br/>They are also dedicated to taking the next step towards a sustainable and ecologically optimized way of producing. Through Cradle to Cradle Certified® standards, DETTO FATTO promotes a sustainable and circular future characterized by a cycle of use, reuse, and rebirth of garments. The C2C Certified® GOLD campaign T-Shirt is made with materials that are considered biological nutrients. The design already considers reuse and recycling into new products.<br/><br/>Additionally, DETTO FATTO is certified by the Green Button, a government run certification label for sustainable textiles that systematically evaluates whether companies take responsibility for respecting human rights and environmental standards in their supply chains. To find out more about the Green Button, visit <Link className="text-primary underline" to="https://www.gruener-knopf.de/en/overview-criteria">www.gruener-knopf.de/en/overview-criteria</Link>
                            </Trans>
                        </div>
                    </div>
                    <div data-id="question2">
                        <span className="">{t(`questions.section1.question2.title`)}</span>
                        <div className="">
                            <Trans i18nKey={`questions.section1.question2.text`}>
                                Cradle to Cradle Certified® is a certification mark licensed by the Cradle to Cradle Products Innovation Institute.<br /><br />The Cradle to Cradle® approach means organic cotton farmers can work without harmful and synthetic fertilizers and pesticides. Likewise, the people involved in the production of the T-shirts are not exposed to harmful chemicals. In addition, renewable energy is used, offsetting carbon emissions. Also, the water used in production is filtered and purified. C2C Certified® products thus support the health of the ecosystem and the communities in which they are produced, as well as the health of the planet. It demonstrates what is now possible in Bangladesh: to produce textile with the highest level of sustainability.<br /><br />To find out more about the C2C Certified® standards, visit <Link className="text-primary underline" to="https://c2ccertified.org">c2ccertified.org</Link>
                            </Trans>
                        </div>
                    </div>
                    <div data-id="question3">
                        <span className="">{t(`questions.section1.question3.title`)}</span>
                        <div className="">
                            <Trans i18nKey={`questions.section1.question3.text`}>
                                OEKO-TEX® MADE IN GREEN is a traceable product label for all types of textiles and leather articles that have been produced in environmentally friendly factories and in safe and socially responsible workplaces. In addition, the MADE IN GREEN label gives consumers the assurance that the textile or leather product is made from materials that have been tested for harmful substances.<br /><br />You can trace the stakeholders of the production process of the 1 Hour Shirt by entering the following code <span className="text-primary">M24LG8M28</span> under this <Link className="text-primary unbderline" to="https://external-link.egnyte.com/?url=https%3A%2F%2Fwww.oeko-tex.com%2Fde%2Flabel-check%3Ftx_avsite_validitycheck%255Baction%255D%3Dcheck%26tx_avsite_validitycheck%255Bcontroller%255D%3DValidity%26cHash%3D07353bd4b930f358743a3f1b45bf9364%23c559">LINK</Link>
                            </Trans>
                        </div>
                    </div>
                    <div data-id="question4">
                        <span className="">{t(`questions.section1.question4.title`)}</span>
                        <div className="">
                            <Trans i18nKey={`questions.section1.question4.text`}>
                                
                            </Trans>
                        </div>
                    </div>
                    <div data-id="question5">
                        <span className="">{t(`questions.section1.question5.title`)}</span>
                        <div className="">
                            <Trans i18nKey={`questions.section1.question5.text`}>
                                
                            </Trans>
                        </div>
                    </div>
                </Accordion>
            </section>
            <section className="container mx-auto mb-16 sm:mb-32 sm:px-32 px-8">
                <h2 className="uppercase text-2xl mb-12 font-extrabold">{t("questions.section2.title")}</h2>
                <Accordion activeItem="question1">
                    <div data-id="question1">
                        <span className="">{t(`questions.section2.question1.title`)}</span>
                        <div className="">
                            <Trans i18nKey={`questions.section2.question1.text`}>
                                The 1 Hour Shirt is an initiative from the charitable NGO Fashion Revolution Germany and the circular fashion brand DETTO FATTO. <br /><br />Find out more about the creators <Link className="text-primary underline" to="/partners">here.</Link>
                            </Trans>
                        </div>
                    </div>
                    <div data-id="question2">
                        <span className="">{t(`questions.section2.question2.title`)}</span>
                        <div className="">
                            <Trans i18nKey={`questions.section2.question2.text`}>
                                
                            </Trans>
                        </div>
                    </div>
                    <div data-id="question3">
                        <span className="">{t(`questions.section2.question3.title`)}</span>
                        <div className="">
                            <Trans i18nKey={`questions.section2.question3.text`}>
                                There are three organizations that will receive the generated donations: The Awaj Foundation, The National Garment Workers Federation (NGWF) and Fashion Revolution Bangladesh. <br /><br />You can find out more about these organizations on our <Link className="text-primary underline" to="/mission">Mission page.</Link>
                            </Trans>
                        </div>
                    </div>
                    <div data-id="question4">
                        <span className="">{t(`questions.section2.question4.title`)}</span>
                        <div className="">
                            <Trans i18nKey={`questions.section2.question4.text`}>
                                
                            </Trans>
                        </div>
                    </div>
                </Accordion>
            </section>
            <section className="container mx-auto mb-16 sm:mb-32 sm:px-32 px-8">
                <h2 className="uppercase text-2xl mb-12 font-extrabold">{t("questions.section3.title")}</h2>
                <Accordion activeItem="question1">
                    <div data-id="question1">
                        <span className="">{t(`questions.section3.question1.title`)}</span>
                        <div className="">
                            <Trans i18nKey={`questions.section3.question1.text`}>
                                
                            </Trans>
                        </div>
                    </div>
                    <div data-id="question2">
                        <span className="">{t(`questions.section3.question2.title`)}</span>
                        <div className="">
                            <Trans i18nKey={`questions.section3.question2.text`}>
                                Living wage benchmarks rely on international standards and local data costs to calculate the cost of basic but decent life. Research has shown that the key components of a credible living wage benchmark include the four following costs: <ul className="list-disc my-4 pl-8"><li>Food using international standards on decent food and local data on food costs</li><li>Housing using international standards on decent housing and local data on housing costs</li><li>Other essential needs such as transportation and healthcare</li><li>Small margin for unforeseen events such as illness or accidents</li></ul>Want to dive deeper? <a target="_blank" className="text-primary" href="https://www.youtube.com/watch?v=l5VW259OroQ" rel="noreferrer">https://www.youtube.com/watch?v=l5VW259OroQ</a>
                            </Trans>
                        </div>
                    </div>
                    <div data-id="question3">
                        <span className="">{t(`questions.section3.question3.title`)}</span>
                        <div className="">
                            <Trans i18nKey={`questions.section3.question3.text`}>
                                
                            </Trans>
                        </div>
                    </div>
                    <div data-id="question4">
                        <span className="">{t(`questions.section3.question4.title`)}</span>
                        <div className="">
                            <Trans i18nKey={`questions.section3.question4.text`}>
                                The vast majority of garment and textile workers around the world earn very low wages and many are trapped in in-work poverty. The minimum wage often does not allow workers to meet basic needs. Without a living wage workers may be:<ul className="list-disc my-4 pl-8"><li>forced to work excessive overtime hours or multiple jobs</li><li>forced to take their children out of school and put them into work</li><li>unable to afford food, health, housing and education</li><li>unable to withstand crises such as ill- health or other unexpected events</li><li>forced to borrow and take loans to purchase basic goods ending up in a cycle of debt</li></ul>
                            </Trans>
                        </div>
                    </div>
                    <div data-id="question5">
                        <span className="">{t(`questions.section3.question5.title`)}</span>
                        <div className="">
                            <Trans i18nKey={`questions.section3.question5.text`}>

                            </Trans>
                        </div>
                    </div>
                    <div data-id="question6">
                        <span className="">{t(`questions.section3.question6.title`)}</span>
                        <div className="">
                            <Trans i18nKey={`questions.section3.question6.text`}>

                            </Trans>
                        </div>
                    </div>
                    <div data-id="question7">
                        <span className="">{t(`questions.section3.question7.title`)}</span>
                        <div className="">
                            <Trans i18nKey={`questions.section3.question7.text`}>
                                    Most brands do not own the factories where their clothes are made and outsource their manufacturing to suppliers. The vast majority of people who make our clothes are directly employed and paid by suppliers; not brands. Brands pay prices for the products they buy from their supply chain partners. Suppliers and manufacturers pay workers wages/salaries. Find out more: <Link className="text-primary underline" to="https://www.goodclothesfairpay.eu/faqs">www.goodclothesfairpay.eu/faqs</Link>
                            </Trans>
                        </div>
                    </div>
                </Accordion>
            </section>
            <ContactForm />
        </main>
    );
}