import logo from "./logo.svg";
import "./App.scss";
import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Link,
} from "react-router-dom";
import { Layout } from "./layout";
import { Home, Mission, Partners, Questions, Privacy, Imprint } from "./pages"; 
import { ParallaxProvider } from 'react-scroll-parallax';

function App() {
  useEffect(() => {
    document.title = 'The 1 Hour Shirt';
  }, []);

  return (
    <ParallaxProvider>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="mission" element={<Mission />} />
            <Route path="partners" element={<Partners />} />
            <Route path="questions" element={<Questions />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="imprint" element={<Imprint />} />
          </Route>
        </Routes>
      </Router>
    </ParallaxProvider>
  );
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default App;
