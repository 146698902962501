import "./style.scss"
import React, { useContext } from 'react';
import Types from "./types";

export const Input = (props) => {
    const Tag = Types[props.type];

    return (
        <>
            {Tag &&
                (<Tag {...props} id={props.id} name={props.name} />)
            }
        </>
    )
};