import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import ContactForm from "../components/contactForm"

export function Mission() {
    const { t, i18n } = useTranslation();

    return (
        <main className="mission overflow-hidden">
            <section className="container mx-auto mb-12 sm:mb-16">
                <div className="header-img w-full">
                    <img src="/Subpages/mission_header.jpg" alt="" className="max-w-full" />
                </div>
            </section>
            <section className="container mx-auto mb-16 sm:mb-32 px-8 sm:px-32">
                <div className="text-center">
                    <h1 className="w-full uppercase text-4xl sm:text-5xl mb-12 font-extrabold montserrat-black">{t("mission.title")}</h1>
                    <p className="w-full text-xl sm:text-2xl leading-tight">
                        <Trans i18nKey="mission.text">
                            <span className="font-semibold text-primary">Our goal is to turn fast fashion into fair fashion.</span><br/><br/>We don’t just want to create awareness around the issue of too cheap fashion, we want to make genuine change to people’s lives. Garment workers around the world deserve to be treated with the same respect as any of us, paid a living wage, and go to work each day in an environment they know is safe.<br/><br/>It is our responsibility as consumers to change the way we think about fashion. It’s time to really demand the industry to change!
                        </Trans>
                    </p>
                </div>
            </section>
            <section className="container mx-auto mb-12 sm:mb-32 flex items-center flex-wrap justify-between">
                <div className="section-img w-full sm:w-2/5 mb-12 sm:‚mb-0">
                    <img src="/Subpages/mission_img1.jpg" alt="" className="max-w-full" />
                </div>
                <div className="text w-full sm:w-1/2 px-8 sm:px-0">
                    <h2 className="text-3xl font-extrabold sm:text-5xl w-full mb-8 uppercase montserrat-black">
                        <Trans i18nKey="mission.section1.title"></Trans>
                    </h2>
                    <p className="text-2xl sm:text-2xl mb-4">
                        <Trans i18nKey="mission.section1.text">
                            <span className="text-primary font-bold">Your purchase is split into two parts.</span><br /><strong>One part (€12.50) goes directly back into the value chain of our T-shirts, to cover the cost of making and distributing it.</strong><br /><br />This includes everyone from the Bangladeshi producer* to the various stakeholders, starting with cotton crops and ending with logistics, who are all committed to fair pay and good working conditions.<br /><br /><strong>The rest is a donation that goes to organizations that fight for workers’ rights and sustainable development.</strong><br /><br />It is incredibly important that the money that is donated goes to creating changes within the garment industry. To ensure that all donations make an impact and are managed in a transparent way, Fashion Revolution Germany e.V. is responsible for receiving and distributing them to different organisations within the garment industry.
                        </Trans>
                    </p>
                    <small>
                        <Trans i18nKey="mission.section1.small"></Trans>
                    </small>
                </div>
            </section>
            <section className="container mx-auto mb-80 sm:mb-32 flex flex-wrap justify-center sm:justify-start relative">
                <div className="absolute top-0 left-0 flex justify-end w-full z-10 py-16 flex items-center h-full">
                    <div className="hidden sm:flex section-img w-full sm:w-2/5 justify-end">
                        <img src="/Subpages/Awaj_Foundation.png" alt="" className="max-w-full" />
                    </div>
                </div>
                <div className="text py-16 sm:py-36 px-12 sm:pl-32 sm:pr-64 bg-neutral-100 px-8 sm:px-0 w-full sm:w-3/4">
                    <h2 className="text-primary text-3xl font-extrabold sm:text-5xl mb-8 uppercase montserrat-black">
                        <Trans i18nKey="mission.section2.title"></Trans>
                    </h2>
                    <p className="text-2xl sm:text-2xl mb-8">
                        <Trans i18nKey="mission.section2.text" />
                    </p>
                    <div className="block sm:hidden w-full absolute left-0 px-8 -bottom-72">
                        <img src="/Subpages/Awaj_Foundation.png" alt="" className="w-full" />
                    </div>
                </div>
            </section>
            <section className="container mx-auto mb-12 sm:mb-32 flex flex-col items-center relative px-8 sm:px-0">
                <blockquote className="quote w-full sm:w-2/3 text-3xl sm:text-4xl flex text-center justify-center items-center flex-col text-primary">
                    <Trans i18nKey="mission.section3.quote" />
                </blockquote>
                <p className="text-neutral-700 text-center mb-12 w-full sm:w-2/3">- <Trans i18nKey="mission.section3.source" /></p>
                <div className="w-full">
                    <p className="w-full sm:w-1/2 text-2xl sm:text-2xl sm:px-32" >
                        <Trans i18nKey="mission.section3.text">
                            The Awaj foundation seeks to protect and empower them, giving them a voice and a platform from which to speak. Want to find out more about the Awaj Foundation?<br /><a className="text-primary font-bold" target="_blank" href="http://awajfoundation.org" rel="noreferrer">http://awajfoundation.org</a>
                        </Trans>
                    </p>
                </div>
            </section>
            <section className="container mx-auto mb-80 sm:mb-32 flex justify-center sm:justify-end relative">
                <div className="absolute top-0 left-0 flex justify-start w-full z-10 py-16 flex items-center h-full">
                    <div className="hidden sm:flex section-img w-2/5">
                        <img src="/Subpages/NGWF.png" alt="" className="max-w-full" />
                    </div>
                </div>
                <div className="text py-16 sm:py-36 px-12 sm:pr-32 sm:pl-64 bg-neutral-100 px-8 sm:px-0 w-full sm:w-3/4">
                    <h2 className="text-primary text-3xl font-extrabold sm:text-5xl mb-8 uppercase montserrat-black">
                        <Trans i18nKey="mission.section4.title"></Trans>
                    </h2>
                    <p className="text-2xl sm:text-2xl mb-8">
                        <Trans i18nKey="mission.section4.text" />
                    </p>
                    <div className="block sm:hidden w-full absolute left-0 px-8 -bottom-72">
                        <img src="/Subpages/NGWF.png" alt="" className="w-full" />
                    </div>
                </div>
            </section>
            <section className="container mx-auto mb-12 sm:mb-32 flex flex-col items-center relative px-8 sm:px-0">
                <blockquote className="quote w-full sm:w-2/3 text-3xl sm:text-4xl flex text-center justify-center items-center flex-col text-primary">
                    <Trans i18nKey="mission.section5.quote" />
                </blockquote>
                <p className="text-neutral-700 text-center mb-12 w-full sm:w-2/3">- <Trans i18nKey="mission.section5.source" /></p>
                <div className="w-full flex justify-end">
                    <p className="w-full sm:w-1/2 text-2xl sm:text-2xl sm:px-32" >
                        <Trans i18nKey="mission.section5.text">
                            Want to find out more about the National Garment Workers Federation? <br /><a className="text-primary font-bold" target="_blank" href="https://ngwfbd.com" rel="noreferrer">https://ngwfbd.com</a>
                        </Trans>
                    </p>
                </div>
            </section>
            <section className="container mx-auto mb-80 sm:mb-32 flex flex-wrap justify-center sm:justify-start relative">
                <div className="absolute top-0 left-0 flex justify-end w-full z-10 py-16 flex items-center h-full">
                    <div className="hidden sm:flex section-img w-full sm:w-2/5 justify-end">
                        <img src="/Subpages/partner_FRB.jpg" alt="" className="max-w-full" />
                    </div>
                </div>
                <div className="text py-16 sm:py-36 px-12 sm:pl-32 sm:pr-64 bg-neutral-100 px-8 sm:px-0 w-full sm:w-3/4">
                    <h2 className="text-primary text-3xl font-extrabold sm:text-5xl mb-8 uppercase montserrat-black">
                        <Trans i18nKey="mission.section6.title"></Trans>
                    </h2>
                    <p className="text-2xl sm:text-2xl mb-8">
                        <Trans i18nKey="mission.section6.text" />
                    </p>
                    <div className="block sm:hidden w-full absolute left-0 px-8 -bottom-72">
                        <img src="/Subpages/partner_FRB.jpg" alt="" className="w-full" />
                    </div>
                </div>
            </section>
            <section className="container mx-auto mb-12 sm:mb-32 flex flex-col items-center relative px-8 sm:px-0">
                <blockquote className="quote w-full sm:w-2/3 text-3xl sm:text-4xl flex text-center justify-center items-center flex-col text-primary">
                    <Trans i18nKey="mission.section7.quote" />
                </blockquote>
                <p className="text-neutral-700 text-center mb-12 w-full sm:w-2/3">- <Trans i18nKey="mission.section7.source" /></p>
                <div className="w-full flex justify-start">
                    <p className="w-full sm:w-1/2 text-2xl sm:text-2xl sm:px-32" >
                        <Trans i18nKey="mission.section7.text">
                            Want to find out more about Fashion Revolution Bangladesh? <br /><a className="text-primary font-bold" target="_blank" href="https://www.fashionrevolution.org/asia/bangladesh/" rel="noreferrer">https://www.fashionrevolution.org/asia/bangladesh/</a>
                        </Trans>
                    </p>
                </div>
            </section>
            <ContactForm />
        </main>
    );
}