import React, {useState} from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const languages = [
    //{key: "de", label: "DE"},
    {key: "en", label: "EN"}
];

export default function Header() {
    const [navigationActive, setNavigationActive] = useState(false);
    const { t, i18n } = useTranslation();

    function toggleNavigation(){
        setNavigationActive(!navigationActive);
    } 
    
    function closeNavigation(){
        setNavigationActive(false);
    }

    return (
        <header className="header relative z-20">
            <div className="container mx-auto flex align-center justify-between py-4 px-6">
                <div className="logo">
                    <NavLink className={({ isActive }) => (isActive ? 'hidden' : '')} to="/">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M30.0699 2.72224L20 20V0C8.95206 0 0 8.95688 0 20C0 31.0431 8.95687 40 20 40C31.0431 40 40 31.0431 40 20C40 12.6283 36.0058 6.18646 30.065 2.72224H30.0699Z" />
                    </svg>
                    </NavLink>
                </div>
                <div className="navigation flex align-center">
                    <div className="languages flex items-center gap-3 px-8">
                        {languages.map((language) => (
                            <span key={language.key} className={i18n.language === language.key ? "text-primary underline" : ""} onClick={() => i18n.changeLanguage(language.key)} role="button" > {language.label}</span>
                        ))}
                    </div>
                    <button onClick={() => toggleNavigation()} ><img src={navigationActive ? "/close.svg" : "/burger.svg"} alt="Navigationtoggle" /></button>
                    <nav className={"absolute flex flex-col bg-white left-0 top-full w-full justify-center pt-36 pb-44 z-10 " + (navigationActive ? "flex" : "hidden")}>
                        <ul className="text-2xl sm:text-6xl text-center uppercase font-extrabold mb-20 sm:leading-tight montserrat-black">
                            <li><NavLink onClick={() => closeNavigation()} className={({ isActive }) => (isActive ? 'underline text-primary' : 'hover:text-primary')} to="/">{t("navigation.home")}</NavLink></li>
                            <li><NavLink onClick={() => closeNavigation()} className={({ isActive }) => (isActive ? 'underline text-primary' : 'hover:text-primary')} to="/partners">{t("navigation.partners")}</NavLink></li>
                            <li><NavLink onClick={() => closeNavigation()} className={({ isActive }) => (isActive ? 'underline text-primary' : 'hover:text-primary')} to="/mission">{t("navigation.mission")}</NavLink></li>
                            <li><NavLink onClick={() => closeNavigation()} className={({ isActive }) => (isActive ? 'underline text-primary' : 'hover:text-primary')} to="/questions">{t("navigation.questions")}</NavLink></li>
                        </ul>
                        <ul className="submenu sm:text-2xl text-center uppercase sm:leading-tight">
                            <li><NavLink onClick={() => closeNavigation()} className={({ isActive }) => (isActive ? 'underline text-primary' : 'hover:text-primary')} to="/privacy">{t("navigation.privacy")}</NavLink></li>
                            <li><NavLink onClick={() => closeNavigation()} className={({ isActive }) => (isActive ? 'underline text-primary' : 'hover:text-primary')} to="/imprint">{t("navigation.imprint")}</NavLink></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    );
}