import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from "../elements/button"

export function Partners() {
    const { t, i18n } = useTranslation();

    return (
        <main className="partners">
            <section className="container mx-auto mb-12 sm:mb-16">
                <div className="header-img w-full">
                    <img src="/Subpages/Creators_Header.jpg" alt="" className="max-w-full" />
                </div>
            </section>
            <section className="container mx-auto mb-16 sm:mb-32 px-8 sm:px-32">
                <div className="text-center flex flex-wrap justify-center">
                    <h1 className="w-full uppercase text-4xl sm:text-5xl mb-12 font-extrabold montserrat-black">{t("partners.title")}</h1>
                    <p className="w-full sm:w-4/5 text-xl sm:text-2xl leading-tight">{t("partners.text")}</p>
                </div>
            </section>
            <section className="container mx-auto mb-32 px-8 sm:px-32">
                <div className="flex flex-col sm:flex-row mb-32 items-center sm:items-start">
                    <div className="logo sm:pt-20 sm:w-2/5 mb-12 sm:mb-0 sm:pr-4">
                        <Link to="https://fashionrevolutiongermany.de/"><img src="/Fashion_Revolution.png" alt="" /></Link>
                    </div>
                    <div className="description w-full sm:w-3/5">
                        <p className="text-xl sm:text-2xl">
                            <span className="font-bold">{t("partners.fashionRevolution.title")} </span> 
                            <Trans i18nKey="partners.fashionRevolution.text">
                                was officially founded in 2015 as the German chapter of the global Fashion Revolution campaign which was created in London in 2013 after the collapse of a garment factory in Bangladesh that killed over 1.100 people and injured more than 2000.<br/><br/><span className="text-primary text-3xl">It has grown to be the world’s largest fashion activism movement.</span><br/><br/>For over ten years they have been campaigning for better working conditions, living wages, and conservation of resources.<br/><br/>Fashion Revolution is dedicated to transforming the fashion industry into one that is clean, safe, fair, transparent, and accountable.<br/><br/>Want to find out more about Fashion Revolution Germany?
                            </Trans>
                        </p>
                        <Link className="mt-4 text-xl sm:text-2xl text-primary flex items-center gap-4 font-bold" to="https://fashionrevolutiongermany.de/">
                            {t("partners.fashionRevolution.title")}
                            <svg width="29" height="17" viewBox="0 0 29 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line x1="27" y1="8.5" x2="1" y2="8.5" stroke="#1123FA" stroke-width="2" stroke-linecap="round" />
                                <path d="M20 1L27.5 8.5L20 16" stroke="#1123FA" stroke-width="2" stroke-linecap="round" />
                            </svg>
                        </Link>
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row-reverse mb-32 items-center sm:items-start">
                    <div className="logo sm:pt-20 sm:w-2/5 mb-12 sm:mb-0 sm:pl-4 flex justify-end">
                        <Link to="https://www.dettofatto.com/de/"><img src="/dettofatto_logo.svg" alt="" /></Link>
                    </div>
                    <div className="description w-full sm:w-3/5">
                        <p className="text-xl sm:text-2xl">
                            <span className="font-bold">{t("partners.dettoFatto.title")} </span> 
                            <Trans i18nKey="partners.dettoFatto.text">
                                is a circular fashion brand where all goods are designed to positively impact people and the planet. <br/><br/><span className="text-primary text-3xl">It is the first and only brand worldwide to specialize exclusively in fashion certified with the independent Cradle to Cradle Certified® label,</span> <br/><br/>following the world’s most advanced (science-based) standard for sustainable products.<br/><br/>Want to find out more about DETTO FATTO?
                            </Trans>
                        </p>
                        <Link className="mt-4 text-xl sm:text-2xl text-primary flex items-center gap-4 font-bold" to="https://www.dettofatto.com/de/">
                            {t("partners.dettoFatto.title")}
                            <svg width="29" height="17" viewBox="0 0 29 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line x1="27" y1="8.5" x2="1" y2="8.5" stroke="#1123FA" stroke-width="2" stroke-linecap="round" />
                                <path d="M20 1L27.5 8.5L20 16" stroke="#1123FA" stroke-width="2" stroke-linecap="round" />
                            </svg>
                        </Link>
                    </div>
                </div>
            </section>
            <section className="bg-primary">
                <div className="container mx-auto pb-32 sm:py-32 flex items-center flex-col sm:flex-row text-white sm:px-32">
                    <div className="img w-full sm:w-2/5 mb-12 sm:mb-0">
                        <img src="/Subpages/partners_image_2.jpg" alt="" />
                    </div>
                    <div className="w-full sm:w-3/5 sm:pl-40 sm:pr-10 px-8">
                        <h3 className="w-full text-center sm:text-left uppercase text-2xl sm:text-5xl mb-12 font-extrabold montserrat-black">
                            <Trans i18nKey="partners.calculateTitle"></Trans>
                        </h3>
                        <div className="action w-full sm:w-4/5">
                            <Button  className="uppercase" type="outline-reverse" to="/#calculator">{t("partners.calculateAction")}</Button>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}