import React, {useState} from 'react';

export const Toggle = ({...props}) => {
    const [toggleState, setToggleState] = useState();

    function handleToggle(e){
        setToggleState(e.target.checked);
        if (props.onChange && typeof props.onChange === 'function'){
            props.onChange(e);
        }
    }

    return (
        <>
            <div className={"toggle-wrapper flex items-center " +( props.className ? props.className : "")}>
                <span className={"text-left uppercase block py-2 " + (toggleState ? "" : "font-extrabold")}>{props.labelOff}</span>
                <span className="px-1 text-primary">|</span>
                <span className={"text-right uppercase block py-2 " + (toggleState ? "font-extrabold" : "")}>{props.labelOn}</span>
                <input id={props.id} type="checkbox" onChange={(e) => handleToggle(e)} value="1" className="toggle-checkbox hidden" checked={toggleState} />
                <label htmlFor={props.id} className="ml-3 toggle"></label>
            </div>
        </>
    );
}