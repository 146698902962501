import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { useTranslation, Trans } from 'react-i18next';
import { Link } from "react-router-dom";
import {Calculator} from "../components/calculator"
import Accordion from "../components/accordion"
import ContactForm from "../components/contactForm"
import "swiper/css"; 
import { useParallax } from 'react-scroll-parallax';

export function Home() {
    const infoTarget = useRef();
    const madeInTarget = useRef();
    const { t, i18n } = useTranslation();

    const [infoTargetElement, setInfoTargetElement] = useState();
    const [madeInTargetElement, setMadeInTargetElement] = useState();
    useEffect(() => {
        setInfoTargetElement(infoTarget.current);
        setMadeInTargetElement(madeInTarget.current);
    }, []);

    const headline = useParallax({ speed: -10, opacity: [2, 0] });
    const infoTitle = useParallax({ translateX: ['-200px', '0px'], opacity: [0, 2], targetElement: infoTargetElement });
    const madeIn = useParallax({ translateX: ['200px', '0px'], opacity: [0, 2], targetElement: madeInTargetElement });

    return (
        <main className="home overflow-hidden">
            <section className="container mx-auto flex flex-wrap justify-center sm:px-32 relative mb-32">
                <div className="w-full flex justify-center mb-12 px-8">
                    <img src="/logo_big.svg" alt="" className="" />
                </div>
                <div className="flex flex-col-reverse lg:flex-row w-full px-8 sm:px-0 relative">
                    <div className="h-full flex items-center w-full sm:w-3/5 lg:w-auto lg:mt-0 relative sm:absolute sm:left-8 z-10">
                        <h2 ref={headline.ref} className="text-5xl sm:text-5xl uppercase font-extrabold text-center montserrat-black">
                            <Trans i18nKey="home.intro.subtitle">
                                The shirt that turns time into a <span className="">currency.</span>
                            </Trans>
                        </h2>
                    </div>
                    <div className="w-full flex justify-center">
                        <div className="w-full sm:w-1/2 flex justify-center">
                            <img src="/Landing_Page/Landing_Page_v4_Header_EN.jpg" alt="" className="" />
                        </div>
                    </div>
                </div>
                <div className="flex justify-center mb-8 sm:mb-8 mt-8">
                    <svg className="hidden sm:block"  height="100" viewBox="0 0 21 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="10.5" cy="109.5" r="7.5" fill="#0000ff" />
                        <circle cx="10.5" cy="10.5" r="7.5" fill="#0000ff" />
                        <line x1="10.5" y1="13" x2="10.5" y2="109" stroke="#0000ff" stroke-width="3" />
                    </svg>
                    <svg className="block sm:hidden my-4"  height="100" viewBox="0 0 21 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="10.5" cy="239.5" r="10.5" fill="#0000ff" />
                        <circle cx="10.5" cy="10.5" r="10.5" fill="#0000ff" />
                        <line x1="10.5" y1="13" x2="10.5" y2="239" stroke="#0000ff" stroke-width="3" />
                    </svg>
                </div>
                <div className="flex items-center flex-col text-center">
                    <h1 className="text-5xl sm:text-5xl uppercase font-extrabold text-center montserrat-black mb-6">
                        {t("home.intro.title")}
                    </h1>
                    <div className="text-2xl sm:text-2xl w-4/5">
                        <p className="mb-8 text-primary font-bold">
                            <Trans i18nKey="home.intro.text1">
                            </Trans>
                        </p>
                        <p className="mb-10">
                            <Trans i18nKey="home.intro.text2">
                            </Trans>
                        </p>
                        <Link className="mt-8 underline" to="/mission">{t("home.intro.link")}</Link>
                    </div>
                </div>
            </section>
            <section id="calculator" className="container mx-auto mb-32">
                <div className="">
                    <div className="w-full sm:pt-24 flex flex-col sm:flex-row">
                        <Swiper pagination={{clickable:true}} navigation={true} autoplay={{delay: 5000, disableOnInteraction: true}} modules={[Autoplay, Pagination, Navigation]} className="mySwiper w-full sm:w-1/2">
                            <SwiperSlide><img src="/Landing_Page/Landing_Page_v4_1_1HourTshirt_March23_739.jpg" alt="" /></SwiperSlide>
                            <SwiperSlide><img src="/Landing_Page/Landing_Page_v4_2_1HourTshirt_March23_784.jpg" alt="" /></SwiperSlide>
                            <SwiperSlide><img src="/Landing_Page/Landing_Page_v4_3_1HourTshirt_March23_919.jpg" alt="" /></SwiperSlide>
                            <SwiperSlide><img src="/Landing_Page/Landing_Page_v4_4_1HourTshirt_March23_654.jpg" alt="" /></SwiperSlide>
                            <SwiperSlide><img src="/Landing_Page/Landing_Page_v4_5_1HourTshirt_March23_978.jpg" alt="" /></SwiperSlide>
                            <SwiperSlide><img src="/Landing_Page/Landing_Page_v4_6_1HourTshirt_March23_594.jpg" alt="" /></SwiperSlide>
                        </Swiper>
                        <div className="bottom-0 right-0 w-full sm:w-1/2 z-10 bg-neutral-100 flex flex-col justify-center mb-16 pt-16">
                            <h3 className="block text-4xl sm:text-5xl px-8 uppercase font-extrabold mb-4 text-center montserrat-black">
                                <Trans i18nKey="home.calculator.title">
                                    CALCULATE YOUR PRICE<span className="text-primary">*</span>
                                </Trans>
                            </h3>
                            <div className="px-8 pt-12 pb-20 text-center">
                                <Calculator />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section ref={infoTarget} className="container mx-auto mb-32 px-8 sm:px-32 relative">
                <h3 className="uppercase text-2xl mb-12 px-2">{t("home.info.title")}</h3>
                <Accordion activeItem="eco">
                    <div data-id="eco">
                        <span>{t("home.info.eco.title")}</span>
                        <div className="h-full">
                            {t("home.info.eco.text")}
                        </div>
                    </div>
                    <div data-id="sizes">
                        <span className="">{t("home.info.sizes.title")}</span>
                        <div className="h-full">
                            <table className="uppercase">
                                <thead className="">
                                    <th className="font-normal pb-8">{t("home.info.sizes.head1")}</th>
                                    <th className="pl-16 font-normal pb-8">{t("home.info.sizes.head2")}</th>
                                    <th className="pl-16 font-normal pb-8">{t("home.info.sizes.head3")}</th>
                                </thead>
                                <tbody className="font-semibold">
                                    <tr>
                                        <td>s</td>
                                        <td className="pl-16">50cm</td>
                                        <td className="pl-16">71cm</td>
                                    </tr>
                                    <tr>
                                        <td>m</td>
                                        <td className="pl-16">54cm</td>
                                        <td className="pl-16">72cm</td>
                                    </tr>
                                    <tr>
                                        <td>l</td>
                                        <td className="pl-16">58cm</td>
                                        <td className="pl-16">74cm</td>
                                    </tr>
                                    <tr>
                                        <td>xl</td>
                                        <td className="pl-16">62cm</td>
                                        <td className="pl-16">76cm</td>
                                    </tr>
                                    <tr>
                                        <td>xxl</td>
                                        <td className="pl-16">66cm</td>
                                        <td className="pl-16">78cm</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div data-id="care">
                        <span>{t("home.info.care.title")}</span>
                        <div className="h-full">
                            <Trans i18nKey="home.info.care.text">
                            </Trans>
                        </div>
                    </div>
                </Accordion>
            </section>
            <section className="container mx-auto mb-16">
                <h3 ref={infoTitle.ref} className="w-full sm:w-2/3 uppercase text-4xl sm:text-5xl mb-12 font-extrabold px-8 sm:px-0 montserrat-black">
                    <Trans i18nKey="home.help.title">
                        HOW YOUR SHIRT <span className="text-primary">HELPS WORKERS IN BANGLADESH.</span>
                    </Trans>
                </h3>
                <div className="w-full mb-16 sm:mb-32 px-8 sm:px-0" style={{ "background": 'url("/Landing_Page/Landing_Page_v4_Grafik.jpg") no-repeat', "background-position-x": "right", "background-size": "33%" }}>
                    <div className="bg-white sm:ml-36 sm:p-6 sm:pt-12 w-full sm:w-2/5">
                        <p className="text-xl sm:text-2xl">
                            {t("home.help.text1")}
                        </p>
                        <p ref={madeInTarget} className="w-full sm:w-3/5 text-xl sm:text-2xl mt-8 font-extrabold mb-6">
                            <Trans i18nKey="home.help.text2">
                                You can find out more about the organisations we work with <Link className="text-primary" to="/mission">here</Link>.
                            </Trans>
                        </p>
                        <small className="w-full sm:w-3/5 block">
                            <Trans i18nKey="home.help.small">
                            </Trans>
                        </small>
                    </div>
                </div>
                <div className="w-full relative mb-16 flex flex-col-reverse sm:flex-row sm:items-center items-end">
                    <img src="/bangladesh.png"  alt="" className="max-w-full w-full sm:w-2/3" />
                    <span className="uppercase text-4xl sm:text-5xl px-12 font-extrabold w-full sm:w-1/3 mb-12 montserrat-black" ref={madeIn.ref}>
                        <Trans i18nKey="home.help.title2">
                            <span className="text-primary">Fair made</span> in Bangladesh
                        </Trans>
                    </span>
                </div>
                <div className="flex w-full justify-end sm:text-right">
                </div>
            </section>
            <section className="container mx-auto mb-32 bg-primary text-white pt-32">
                <h3 className="w-full text-center uppercase text-4xl sm:text-5xl mb-4 font-extrabold montserrat-black">{t("home.partners.title")}</h3>
                <div className="flex flex-col w-full py-20 px-8 sm:px-44 ">
                    <p className="text-center text-xl sm:text-2xl mb-16">
                        <Trans i18nKey="home.partners.text">
                            This shirt is a collaboration between Fashion Revolution Germany, who is dedicated to changing the fashion industry for the better and DETTO FATTO, a circular fashion brand that operates in a sustainable, responsible manner. <br /><br /><strong>Find out more about us </strong><Link className="underline font-bold" to="/partners">here</Link>
                        </Trans>
                    </p>
                    <div className="flex flex-col sm:flex-row items-center justify-center logos gap-8">
                        <img src="/xx_FRG_Logo_RZ-2 Kopie_.svg" alt="" />
                        <img src="/and.svg" alt="" />
                        <img src="/DETTOFATTO.png" alt="" />
                    </div>
                </div>
            </section>
            <section className="container flex justify-center flex-col items-center mx-auto mb-32 px-8 sm:px-0">
                <h3 className="w-full sm:w-2/3 text-center uppercase text-4xl sm:text-5xl mb-12 font-extrabold montserrat-black">
                    <Trans i18nKey="home.qa.title">
                    </Trans>
                </h3>
                <p className="text-center text-xl sm:text-2xl mb-16">
                    <Trans i18nKey="home.qa.text">
                        Need a little more information? <br />Check out our <Link className="text-primary" to="/questions">FAQ section.</Link>
                    </Trans>
                </p>
            </section>
            <ContactForm />
        </main>
    );
}