import React from 'react';
import './style.scss';
import { Link } from "react-router-dom";

export function Button({label, type = "primary", className, ...props}) {

    return (
        props.to ? (
            <Link to={props.to} className={`button font-extrabold button-${type} ${className}`} {...props}>
                {props.children}
            </Link>
        ) : (
            <button className = {`button font-extrabold button-${type} ${className}`} {...props }>
                { props.children }
            </button >
        )
    );
}