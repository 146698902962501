import React, {useRef, useState} from 'react';
import { Input } from "../../elements/input"
import { Button } from "../../elements/button"
import { useTranslation } from 'react-i18next';
import './style.scss';

const formatLocals = {
    de: "de-DE",
    en: "en-EN",
}

export function Calculator() {
    const { t, i18n } = useTranslation();
    const [income, setIncome] = useState("");
    const incomeFormatted = useRef("");
    const mode = useRef(false);
    const [price, setPrice] = useState(null)
    const [errors, setErrors] = useState({})

    function calculatePrice(){
        if (income === null || income === ""){
            setErrors({ ...errors, income: "Please fill in this field."});
        }else{
            if (!mode.current){
                setPrice(priceFormat(Math.max(12.5, parseFloat(income) / 176)));
            }else{
                setPrice(priceFormat(Math.max(12.5 ,parseFloat(income) / 2112)));
            }
            setErrors({ ...errors, income: undefined })
        }
    }

    function filterIncomeValue(value){
        incomeFormatted.current = value;
        if (i18n.language === 'en'){
            return setIncome(value.replace(/[^0-9.]/g, ''));
        } else if (i18n.language === 'de'){
            return setIncome(value.replace(/[^0-9,]/g, ''));
        }
    }

    function priceFormat(price) {
        if(price){
            return new Intl.NumberFormat(formatLocals[i18n.language], { style: 'currency', currency: 'EUR' }).format(parseFloat(price))
        }
        return price;
    }

    return (
        <div className="calculator flex justify-end flex-col items-end">
            {price === null ? (
                <>
                    <div className="flex items-center justify-center w-full">
                        <span className=" mr-2">Enter your gross salary per</span>
                        <Input className="w-48" onChange={(e) => mode.current = e.target.checked} labelOn={t("calculator.year")} labelOff={t("calculator.month") } id="calculator-mode" type="toggle"></Input>
                    </div>
                    <div className="sm:px-10 flex flex-col w-full">
                        <div className="relative">
                            <div className="absolute text-2xl sm:text-3xl block text-center w-full py-2 mt-8 pointer-events-none">{priceFormat(income)}</div>
                            <input step="0.01" min="0" name="income" onFocus={() => setIncome("")} onChange={(e) => setIncome(e.target.value.replace(/[^0-9.,]/, "").replace(",", ".").replace(/^(\d+[.]?\d{0,2})\d*$/, "$1"))} value={income} placeholder={t("calculator.incomePlaceholder")} type="text" className={"w-full text-neutral-100 bg-transparent mb-12 mt-8 py-2 text-center text-2xl sm:text-3xl border-b border-b-black font-extrabold select-none " + (errors.income ? "border-red-400" : "border-white")} />
                        </div>
                        <Button onClick={() => calculatePrice()} className="uppercase">{t("calculator.calculateAction")}</Button>
                    </div>
                    <p className="mx-16 mt-12">
                        {t("calculator.legal")}
                    </p>
                </>
            ) : (
                <>
                    <div className="w-full text-center text-4xl">
                        {t("calculator.yourPrice")}
                    </div>
                    <div className="w-full text-center text-3xl my-12 font-extrabold">
                        {price}
                    </div>
                    <div className="flex flex-col items-center w-full">
                        <Button to="https://www.dettofatto.com/de/one-hour-shirt" className="uppercase">{t("calculator.shopAction")}</Button>
                        <span role="button" onClick={() => setPrice(null)} className="text-xs cursor-pointer mt-4 mb-2 underline hover:underline">{t("calculator.backAction")}</span>
                    </div>
                    <p className="mt-6">
                        {t("calculator.legal")}
                    </p>
                </>
            )}
        </div>
    );
}