import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

let docCache = {}

export function Imprint() {
    const { t, i18n } = useTranslation();
    const frame = useRef();

    function iframeLoaded() {
        var iFrame = frame.current;
        if (iFrame) {
            // here you can make the height, I delete it first, then I make it again
            iFrame.height = "";
            iFrame.height = (iFrame.contentWindow.document.body.scrollHeight + 50) + "px";
        }
    }

    return (
        <main className="imprint container mx-auto px-8 my-12">
            <section className="mb-16 sm:mb-16">
                <h1 className="w-full text-left uppercase text-4xl sm:text-7xl mb-12 font-extrabold montserrat-black">{t("imprint.title")}</h1>
            </section>
            <section>
                <iframe ref={frame} onLoad={() => iframeLoaded()} className="w-full" src={`/locales/${i18n.language}/imprint.html`} frameborder="0"></iframe>
            </section>
        </main>
    );
}