import "./style.scss"
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from "../../elements/button"

const ContactForm = ({ ...props }) => {
    const { t, i18n } = useTranslation();

    return (
        <section className={"contactform bg-neutral-100 " + props.className}>
            <div className="container mx-auto pt-24 pb-24 sm:pb-32 px-8 sm:px-32 flex flex-col items-center">
                <h3 className="uppercase text-4xl sm:text-5xl font-extrabold mb-12 montserrat-black">{t("contactForm.title")}</h3>
                <h4 className="text-xl sm:text-2xl">{t("contactForm.subtitle")}</h4>
                <a className="text-xl sm:text-2xl text-primary text-left block font-bold"href="mailto:contact@the1hourshirt.com">contact@the1hourshirt.com</a>
            </div>
        </section>
    )
};

export default ContactForm;