import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header.jsx";
import Footer from "./Footer.jsx";

export function Layout() {
  const location = useLocation();

  useEffect(() => {
    if (!window._mtm) return;
    window._mtm.push({
      event: "custom.pageview",
      path: location.pathname,
    });
  }, [location.pathname]);

  return (
    <div className="layout">
      <Header></Header>
      <Outlet />
      <Footer></Footer>
    </div>
  );
}
