import "./style.scss"
import React, { useState } from 'react';

const Accordion = ({ activeItem, ...props }) => {
    const [active, setActive] = useState(activeItem);
    function outTest(value) {
        setActive(value);
    }

    return (
        <div className={"accordion flex flex-wrap w-full " + props.className}>
            {props.children &&
                <>
                    <div className={"flex w-full flex-col"}>
                        {Array.isArray(props.children) ? (
                            props.children.map((child, index) => {
                                    return (
                                        <div className={"border-b-2 border-width-2 border-black flex flex-col w-full " + (index === 0 ? "border-t-2" : "")}>
                                            <div 
                                                role="button" 
                                                onClick={() => outTest(child.props["data-id"])} 
                                                className={"headline flex items-center justify-between text-2xl smtext-3xl uppercase font-semibold py-3 sm:py-5 px-2 " + (child.props["data-id"] == active ? 'active' : '')}
                                            >
                                                {child.props.children[0]}
                                            </div>
                                            <div 
                                                className={"content-wrapper overflow-hidden w-full transition-all"}
                                                style={{ 'maxHeight': (child.props["data-id"] == active ? '1000px' : '0px')}}
                                            >
                                                <div className="content w-full py-10 px-2 sm:text-xl">
                                                    {child.props.children[1]}
                                                </div>
                                            </div>
                                        </div>
                                    )
                            })
                        ) : (
                           null
                        )}
                    </div>
                </>
            }
        </div>
    )
};

export default Accordion;