import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function Footer() {
    const { t, i18n } = useTranslation();

    return (
        <footer className="footer bg-black">
            <div className="flex flex-col justify-between px-8 sm:px-0 sm:flex-row container mx-auto text-white py-16 flex-wrap">
                <div className="w-full sm:w-1/4 order-2 mb-8 sm:mb-0">
                    <h3 className="text-primary font-extrabold text-2xl mb-8 uppercase">{t("footer.col1.title")}</h3>
                    <p className="text-neutral-200">
                        {t("footer.col1.text")}
                    </p>
                </div>
                <div className="w-full sm:w-1/2 shrink-0 flex flex-col order-3 mb-8 sm:mb-0">
                    <div className="flex flex-col sm:flex-row justify-between">
                        <div className="w-full sm:w-1/2 mb-8 sm:mb-0">
                            <h3 className="text-primary font-extrabold text-2xl mb-8 uppercase">{t("footer.col2.title")}</h3>
                            <nav className="">
                                <ul className="font-extrabold">
                                    <li className=""><Link to="/">{t("navigation.home")}</Link></li>
                                    <li><Link to="/partners">{t("navigation.partners")}</Link></li>
                                    <li><Link to="/mission">{t("navigation.mission")}</Link></li>
                                    <li><Link to="/questions">{t("navigation.questions")}</Link></li>
                                    <li><Link to="/privacy">{t("navigation.privacy")}</Link></li>
                                    <li><Link to="/imprint">{t("navigation.imprint")}</Link></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="w-full sm:w-1/2">
                            <h3 className="text-primary font-extrabold text-2xl mb-8 uppercase">{t("footer.col3.title")}</h3>
                            <nav className="">
                                <ul className="font-extrabold">
                                    <li><Link to="https://fashionrevolutiongermany.de/">{t("footer.col3.partners.1")}</Link></li>
                                    <li><Link to="https://www.dettofatto.com/de/">{t("footer.col3.partners.2")}</Link></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center sm:justify-end order-1 sm:order-3 mb-8 sm:mb-0 gap-4">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M30.0699 2.72224L20 20V0C8.95206 0 0 8.95688 0 20C0 31.0431 8.95687 40 20 40C31.0431 40 40 31.0431 40 20C40 12.6283 36.0058 6.18646 30.065 2.72224H30.0699Z" />
                    </svg>
                    <Link to="https://www.instagram.com/the1hourshirt"><img src="/instagram.svg" alt="" /></Link>
                </div>
                <div className="w-full flex flex-col sm:flex-row items-center justify-between mt-12 sm:mt-24 order-4">
                    <div className="copyright text-neutral-200 uppercase">© 2023, The 1 hour shirt</div>
                </div>
            </div>
        </footer>
    );
}